* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: Montserrat, sans-serif;
  color: seashell;
}

h1 {
  z-index: 1;
  padding-top: 1rem;
}

.content {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.content:before {
  content: '';
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -100;
}

.logo--section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo--section a {
  text-decoration: none;
}

.logo--section img {
  max-width: 50%;
  max-height: auto;
}

.button-catalog {
  align-items: center;
  background-image: linear-gradient(144deg, #af40ff, #f342bb 50%, #a100eb);
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #ffffff;
  display: flex;
  font-family: Karla, sans-serif;
  font-size: 20px;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  min-width: 140px;
  padding: 10px 15px;
  margin-top: 1rem;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
}

.button-catalog:active,
.button-catalog:hover {
  outline: 0;
}

@media (min-width: 768px) {
  .button-catalog {
    font-size: 24px;
    min-width: 196px;
  }
}
